<template>
  <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2 col-span-2 overflow-hidden dark:text-white"
       v-if="details || loadingDetails">
    <div v-if="details" class="text-center sticky top-0 h-full grid grid-cols-3 w-ful gap-2 overflow-hidden">
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full overflow-y-auto">
        <div class="flex flex-col h-full">
          <div class="m-2">
            <div class="text-center">
              <div class="flex justify-between" v-if="hasTable">
                <a @click="$emit('hideTable')">
                  <svg v-if="!hideTable" class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6l6 6z"/>
                  </svg>
                  <svg v-else class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/>
                  </svg>

                </a>
                <a @click="openItemInNewTab">
                  <svg class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                       xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2zM14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3z"/>
                  </svg>
                </a>
              </div>
              <p class="text-xl mt-6 dark:text-white">{{details.properties.get('order_reference')}}</p>
              <p class="text-v3-800 dark:text-v3-gray-200" v-if="details.properties.get('purchase_order_number')">
                {{details.properties.get('purchase_order_number')}}</p>
              <p class="text-v3-800 opacity-60 dark:text-v3-gray-200" v-if="details.properties.get('quick_reference')">{{details.properties.get('quick_reference')}}</p>
                <p class="flex justify-center my-2">
                    <status-badge :status="details.properties.get('status')"></status-badge>
                </p>

                <div class="grid grid-cols-2 mt-4 gap-4">
                    <p class="text-right">
                        <span class="text-v3-gray-800 dark:text-v3-gray-200">
                            <span class="font-medium"> {{orderProject.entity.properties.get('name')}}</span><br/>
                            <span>{{orderSite.entity.properties.get('name')}}</span>
                        </span>
                        </p>
                    <p class="text-v3-base-800 dark:text-v3-base-300 text-xs text-left">
                        <span v-if="orderSite.entity.properties.get('formattedAddress')" v-html="orderSite.entity.properties.get('formattedAddress')"></span>
                        <span  v-else v-html="orderProject.entity.properties.get('formattedAddress')"></span>
                    </p>
                </div>

              <div class="grid grid-cols-2 items-center gap-4">
                <div class="flex justify-end">
                  <div class="flex pr-2">
                    <div v-if="orderUser.entity.properties.get('profile_image')">
                      <img
                          class="inline-block h-12 w-12 rounded-full my-4"
                          :src="'/storage/profile_images/' + orderUser.entity.properties.get('profile_image')">
                    </div>
                    <div v-else-if="orderUser.entity.properties.get('oss_profile_image') && orderUser.entity.properties.get('oss_profile_image').includes('on-site-selfie')">
                      <img
                          class="inline-block h-12 w-12 rounded-full my-4"
                          :src="orderUser.entity.properties.get('oss_profile_image').replace('public', 'storage')">
                    </div>
                    <div v-else class="flex justify-center">
                        <span
                            class="inline-flex items-center justify-center h-12 w-12 rounded-full my-4 bg-gray-400">
                            <span
                                class="text-xl font-medium leading-none text-white">{{ initials }}</span>
                        </span>
                    </div>
                  </div>
                </div>
                  <div class="text-left">{{orderUser.entity.properties.get('name')}}</div>
              </div>

                <div class="text-left grid grid-cols-3 gap-x-2">
                    <div v-for="(action, index) in remainingActions">
                        <template v-if="!action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <email-preview-form additionalButtonClasses="my-2 w-full" v-if="action[0] === 'send-quotation-request'"
                                                :action="action[1]"
                                                :title="getTitle(action[0], action.title)"
                                                :actionKey="action[0]"
                                                :onSuccessPath="$router.currentRoute.fullPath"
                                                :buttonType="getColourType(action[0], index)"
                                                :redirectOnSuccess="false"
                                                @success="formSuccess"></email-preview-form>
                            <returns-form additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'create-return-request'"
                                          :response="details" :action="action[1]"
                                          :actionKey="action[0]"
                                          :onSuccessPath="$router.currentRoute.fullPath"
                                          :buttonType="getColourType(action[0], index)"
                                          :title="getTitle(action[0], action.title)"
                                            :redirect-on-success="false"
                                          @success="$emit('success')">
                            </returns-form>
                            <update-scheduled-file-form v-else-if="action[0] === 'update-scheduled-file'"
                                                        :action="action[1]"
                                                        :actionKey="action[0]"
                                                        :onSuccessPath="$router.currentRoute.fullPath"
                                                        :secondAction="action[1]"
                                                        additionalButtonClasses="my-2 w-full"
                                                        :buttonType="getColourType(action[0], index)"
                                                        :redirect-on-success="false"
                                                        @success="$emit('success')"
                            ></update-scheduled-file-form>
                            <ready-for-quote-form additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'mark-as-ready-for-quote'" :action="action[1]" :actionKey="action[0]" :properties="response.body.properties" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)":redirect-on-success="false"
                                                  @success="$emit('success')"></ready-for-quote-form>
                            <review-quotes-form additionalButtonClasses="my-2 w-full" v-else-if="action[0] === 'reject-competing-quote'" :response="response"
                                                :firstAction="action[1]" :secondAction="remainingActions.filter(action => action.fields.isEmpty() && action.name ==='approve-competing-quote').first()"
                                                :onSuccessPath="$router.currentRoute.fullPath"
                                                :redirect-on-success="false"
                                                @success="$emit('success')"
                            ></review-quotes-form>
                            <form-action v-else :title="getTitle(action[0], action.title)" :response="details" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)" @success="formSuccess"></form-action>
                        </template>
                        <template v-else-if="action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <confirmation-action :title="getTitle(action[0], action.title)" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="action.name === 'delete-order'" :properties="details.properties" :buttonType="getColourType(action[0], index)" :on-success-path="action.name === 'delete-order' ? '/orders': $router.currentRoute.fullPath" @success="formSuccess" :redirect-on-success="false"

                            ></confirmation-action>
                        </template>
                    </div>
                </div>

              <div v-if="selectedQuote" class="grid grid-cols-3 gap-x-4 gap-y-4 my-4 5 p-2">
                <div v-if="selectedQuote">
                  <dt class="text-xs font-normal text-v3-gray-800 dark:text-v3-base-200">Supplier</dt>
                  <dd class="text-sm text-v3-gray-800 font-bold dark:text-v3-gray-200">{{ selectedQuote.entity.properties.get('company_name') }}</dd>
                </div>
                <div v-if="selectedQuote">
                  <dt class="text-xs font-normal text-v3-base-800 dark:text-v3-base-200">Manufacturer</dt>
                  <dd class="text-sm text-v3-gray-800 font-bold dark:text-v3-gray-200">
                    <template v-if="selectedQuote.entity.properties.get('manufacturer')">
                      {{ selectedQuote.entity.properties.get('manufacturer') }}
                    </template>
                    <template>N/A</template>
                  </dd>
                </div>
                <div v-if="selectedQuote">
                  <dt class="text-xs font-normal text-v3-base-800 dark:text-v3-base-200 flex items-center justify-center">
                      <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M9.01 14H2v2h7.01v3L13 15l-3.99-4zm5.98-1v-3H22V8h-7.01V5L11 9z"/></svg>
                      Check
                  </dt>
                  <dd class="text-sm text-v3-gray-800 font-bold flex justify-center dark:text-v3-gray-200">
                    <template v-if="selectedQuote.entity.properties.get('invoice_total') === '£0.00' &&
                    selectedQuote.entity.properties.get('credit_notes_total') === '£0.00' &&
                    selectedQuote.entity.properties.get('eur_invoice_total') === '€0.00' &&
                    selectedQuote.entity.properties.get('eur_credit_notes_total') === '€0.00'">
                      N/A
                    </template>
                    <template v-else-if="selectedQuote.entity.properties.get('invoices_over_quote') !== true">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none"
                           viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                      </svg>
                    </template>
                    <template v-else>
                        <span class="text-error">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6">
                              <path stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                            </svg>
                        </span>
                    </template>
                  </dd>
                </div>

                <div v-if="selectedQuote" class="rounded-lg p-2 bg-v3-gray-50 bg-opacity-5 hover:bg-opacity-20 cursor-pointer" :class="{'bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20': tabOpen === 'selected-quote'}" @click="tabOpen='selected-quote'">
                    <dt v-if="!selectedQuote.entity.properties.get('is_proforma')" class="text-xs font-normal text-v3-base-800 dark:text-v3-base-200">Quote</dt>
                    <dt v-else class="text-xs font-normal text-v3-base-800 dark:text-v3-base-200">Proforma Quote</dt>
                    <dd class="text-v3-gray-800 font-bold my-2 dark:text-v3-gray-200" :class="{'text-error': selectedQuote.entity.properties.get('invoices_over_quote') === true}">{{ selectedQuote.entity.properties.get('total') }}</dd>
                </div>
                <div v-if="cmsQuote && !details.properties.get('is_fully_approved')" class="rounded-lg p-2 bg-v3-gray-50 bg-opacity-5 hover:bg-opacity-20 cursor-pointer" :class="{'bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20': tabOpen === 'cms-quote'}" @click="tabOpen='cms-quote'">
                    <dt class="text-xs font-normal text-v3-base-800 dark:text-v3-base-200">CMS Quote</dt>
                    <dd class="text-v3-gray-800 font-bold my-2 dark:text-v3-gray-200">{{ cmsQuote.entity.properties.get('total') }}</dd>
                </div>
                <div v-if="selectedQuote && details.properties.get('show_invoices_credits')" class="rounded-lg p-2 bg-v3-gray-50 bg-opacity-5 hover:bg-opacity-20 cursor-pointer" :class="{'bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20': tabOpen === 'invoices'}" @click="tabOpen='invoices'">
                    <dt class="text-xs font-normal text-v3-base-800 dark:text-v3-base-200">Invoice</dt>
                    <dd class=" text-v3-gray-800 font-bold my-2 dark:text-v3-gray-200" :class="{'text-error': selectedQuote.entity.properties.get('invoices_over_quote') === true}">
                        <template v-if="selectedQuote.entity.properties.get('invoice_total') !== '£0.00' || selectedQuote.entity.properties.get('eur_invoice_total') === '€0.00'">
                            {{ selectedQuote.entity.properties.get('invoice_total') }}
                        </template>
                        <template v-if="selectedQuote.entity.properties.get('eur_invoice_total') !== '€0.00'">
                            {{ selectedQuote.entity.properties.get('eur_invoice_total')}}
                        </template>
                    </dd>
                </div>
                <div v-if="selectedQuote && details.properties.get('show_invoices_credits')" class="rounded-lg p-2 bg-v3-gray-50 bg-opacity-5 hover:bg-opacity-20 cursor-pointer" :class="{'bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20': tabOpen === 'credits'}" @click="tabOpen='credits'">
                    <dt class="text-xs font-normal text-v3-base-800 dark:text-v3-base-200">Credit</dt>
                    <dd class="text-v3-gray-800 font-bold my-2 dark:text-v3-gray-200" :class="{'text-error': selectedQuote.entity.properties.get('invoices_over_quote') === true}">
                        <template v-if="selectedQuote.entity.properties.get('credit_notes_total') !== '£0.00' || selectedQuote.entity.properties.get('eur_credit_notes_total') === '€0.00'">
                            {{ selectedQuote.entity.properties.get('credit_notes_total') }}
                        </template>
                        <template v-if="selectedQuote.entity.properties.get('eur_credit_notes_total') !== '€0.00'">
                            {{ selectedQuote.entity.properties.get('eur_credit_notes_total')}}
                        </template>
                    </dd>
                </div>
              </div>

              <header-link class="my-4" @click="openTab('order-list')" :isActive="tabOpen === 'order-list'" activeClass="bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20">
                <template v-slot:title>
                  <div class="text-left">
                    Order List
                  </div>
                </template>
              </header-link>
              <header-link class="my-4" @click="openTab('order-details')" :isActive="tabOpen === 'order-details'" activeClass="bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20">
                <template v-slot:title>
                  <div class="text-left">
                    Order Details
                  </div>
                </template>
              </header-link>
              <header-link class="my-4" @click="openTab('order-activity')" :isActive="tabOpen === 'order-activity'" activeClass="bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20">
                <template v-slot:title>
                  <div class="text-left">
                    Order Activity
                  </div>
                </template>
              </header-link>
                <header-link class="my-4" @click="openTab('quotations')" :isActive="tabOpen === 'quotations'" activeClass="bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20">
                    <template v-slot:title>
                        <div Class="text-left">
                            Quotations
                        </div>
                    </template>
                </header-link>
                <div class="grid grid-cols-2 gap-2">
                    <header-link class="my-4" @click="openTab('attachments')" :isActive="tabOpen === 'attachments'" activeClass="bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20">
                        <template v-slot:title>
                            <div Class="text-left">
                                Attachments
                            </div>
                        </template>
                    </header-link>
                    <header-link class="my-4" @click="openTab('notes')" :isActive="tabOpen === 'notes'" activeClass="bg-v3-green bg-opacity-20 dark:bg-v3-green dark:bg-opacity-20">
                        <template v-slot:title>
                            <div Class="text-left">
                                Notes
                            </div>
                        </template>
                    </header-link>
                </div>
            </div>
          </div>

        </div>
      </div>
      <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full col-span-2 overflow-y-auto text-left">
        <div class="p-2 h-full w-full">
          <div v-if="tabOpen === 'order-list'">
            <order-items-pagination ref="orderItems" :entity="details" :collapsable="false" class="my-2"
                                    :action="updateAction" :editing="editingOrderItems"
                                    :updateActionData="updateActionData" @saveClicked="saveItems"
                                    @editClicked="editingOrderItems = true" @removeItem="removeItem">
                <template  v-slot:additional-buttons>
                    <div class="flex">
                        <div class="mx-1" v-for="(action, index) in details.actions.filter(action => action.name === 'add-goods-in-note' || action.name === 'mark-as-fully-delivered' || action.name === 'split-order')">
                        <goods-in-note-form v-if="action[0] === 'add-goods-in-note' || action[0] === 'mark-as-fully-delivered' "
                                            :response="details" :action="action[1]"
                                            :actionKey="action[0]"
                                            :onSuccessPath="$router.currentRoute.fullPath"
                                            additionalButtonClasses="my-2 w-full"
                                            :buttonType="getColourType(action[0], index)"
                                            :title="getTitle(action.name, action.title)"
                                            @success="formSuccess"
                        ></goods-in-note-form>
                        <split-order-form v-else-if="action[0] === 'split-order'"
                                          :response="details" :action="action[1]"
                                          :actionKey="action[0]"
                                          :onSuccessPath="$router.currentRoute.fullPath"
                                          additionalButtonClasses="my-2 w-full"
                                          :buttonType="getColourType(action[0], index)"
                                            @success="formSuccess"
                        ></split-order-form>
                        </div>
                    </div>
                    </template>

            </order-items-pagination>

          </div>
          <div v-if="tabOpen === 'order-details'">
            <blank-header :withEditIcon="true" @editClicked="editDetails" @saveClicked="saveDetails" :editing="editing">
              <template v-slot:title>Order Details</template>
            </blank-header>
            <order-basic-details-pagination :entity="details" :collapsable="false" class="my-2" :action="updateAction"
                                            :editing="editing"
                                            :updateActionData="updateActionData"></order-basic-details-pagination>
            <order-contract-info-pagination :siteManager="orderUser" :entity="details" :collapsable="false" class="my-2"
                                            :action="updateAction" :editing="editing"
                                            :updateActionData="updateActionData"></order-contract-info-pagination>
            <order-schedule-pagination :entity="details" :collapsable="false" class="my-2" :action="updateAction"
                                       :editing="editing"
                                       :updateActionData="updateActionData"></order-schedule-pagination>
            <order-attachments-pagination :entity="details" :collapsable="false" class="my-2" :action="updateAction"
                                          :editing="editing"
                                          :updateActionData="updateActionData"></order-attachments-pagination>
          </div>
          <order-activity-feed v-if="tabOpen==='order-activity'" :entity="details"></order-activity-feed>

            <div v-if="tabOpen === 'selected-quote' && selectedQuote" class="w-full h-full">
                <blank-header>
                    <template v-slot:title>
                        <span class="flex justify-between w-full items-center">
                            <span>Selected Quote</span>
                            <form-action v-if="!updateQuoteAction.fields.isEmpty()" title="Update" :action="updateQuoteAction" :actionKey="updateQuoteAction.name" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" @success="$emit('success')"></form-action>
<!--                        <a>Update</a>-->
                        </span>
                    </template>
                </blank-header>
                <download-pdf-inline v-if="selectedQuote" :url="selectedQuote.entity.properties.get('pdf_links')">

                </download-pdf-inline>
            </div>
            <div v-if="tabOpen === 'invoices'">
                <order-invoices-pagination :order="details" @success="formSuccess">
                    <template  v-slot:additional-buttons>
                    <reconcile-order-form v-if="createInvoiceAction"
                                          :action="createInvoiceAction"
                                          :actionKey="createInvoiceAction.name"
                                          :onSuccessPath="$router.currentRoute.fullPath"
                                          additionalButtonClasses="my-2 w-full"
                                          :buttonType="getColourType(createInvoiceAction.name, 0)"
                                          title="Add Invoice"
                                          @success="formSuccess"
                                          :redirect-on-success="false"
                    >
                    </reconcile-order-form>
                    </template>
                </order-invoices-pagination>

            </div>
            <div v-if="tabOpen === 'credits'">
                <order-credit-notes-pagination :order="details" @success="formSuccess" >
                    <template  v-slot:additional-buttons>
                    <new-credit-note-form v-if="createCreditNoteAction"
                                          :action="createCreditNoteAction"
                                          :actionKey="createCreditNoteAction.name"
                                          :onSuccessPath="$router.currentRoute.fullPath"
                                          additionalButtonClasses="my-2 w-full"
                                          :buttonType="getColourType(createCreditNoteAction.name, 0)"
                                          title="Add Credit Note"
                                          @success="formSuccess"
                                          :redirect-on-success="false"
                    ></new-credit-note-form>
                    </template>
                </order-credit-notes-pagination>
            </div>

            <div v-if="tabOpen === 'quotations'">
                <order-quotations-pagination :order="details" @success="formSuccess">
                    <template  v-slot:additional-buttons>
                        <div class="flex justify-end">
                            <div class="mx-1" v-for="(action, index) in details.actions.filter(action =>action.name === 'select-quote-for-approval' || action.name === 'select-competing-quote' || action.name === 'request-update')">
                                <select-quote-form v-if="action[0] === 'select-quote-for-approval' || action[0] === 'select-competing-quote' "
                                                   :action="action[1]"
                                                   :actionKey="action[0]"
                                                   :onSuccessPath="$router.currentRoute.fullPath"
                                                   :secondAction="details.actions.filter(action2 => !action2.fields.isEmpty() && action2.name ==='select-quote-and-ask-cms-to-beat').first()"
                                                   buttonType="positive"
                                                   title="Select"
                                                   :redirectOnSuccess="false"
                                                   @success="formSuccess"
                                ></select-quote-form>
                                <form-action v-else-if="!action[1].fields.isEmpty()" :title="getTitle(action[0], action.title)" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)" @success="formSuccess"></form-action>

                                <confirmation-action v-else-if="action[1].fields.isEmpty()"
                                                     :action="action[1]" :actionKey="action[0]" :title="getTitle(action[0], action.title)" :redirectOnSuccess="action.name === 'delete-order'" :properties="details.properties" :on-success-path="action.name === 'delete-order' ? '/orders': $router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)" @success="formSuccess"

                                ></confirmation-action>

                            </div>
                        </div>
                    </template>
                </order-quotations-pagination>
            </div>

            <div v-if="tabOpen === 'attachments'">
                <order-attachments :order="details"></order-attachments>
            </div>

            <div v-if="tabOpen === 'notes'">
                <order-notes :order="details"></order-notes>
            </div>

        </div>
      </div>
    </div>
    <loading v-else class="sticky top-0"></loading>
      <modal v-if="showModal" @removeFromDisplay="showModal = false" @perform="showModal = false" title="Unsaved Changes" confirmationString="" :has-confirmation-action="false">
          Please save changes before changing page.
      </modal>
  </div>
</template>
<script>
import BlankHeader from "@/v3/components/headers/BlankHeader.vue"
import HeaderLink from "@/v3/components/headers/HeaderLink.vue"
import Loading from '@/components/Loading'
import OrderActivityFeed from "@/v3/components/OrderActivityFeed.vue"
import OrderAttachmentsPagination from "@/v3/components/pagination/OrderAttachmentsPagination.vue"
import OrderBasicDetailsPagination from "@/v3/components/pagination/OrderBasicDetailsPagination.vue"
import OrderContractInfoPagination from "@/v3/components/pagination/OrderContractInfoPagination.vue"
import OrderItemsPagination from "@/v3/components/pagination/OrderItemsPagination.vue"
import OrderSchedulePagination from "@/v3/components/pagination/OrderSchedulePagination.vue"
import StatusBadge from "@/components/StatusBadge.vue"
import {mapGetters} from "vuex";
import fileDownload from "js-file-download";
import DownloadPdfInline from "@/v3/components/DownloadPdfInline.vue";
import OrderInvoicesPagination from "@/v3/components/pagination/OrderInvoicesPagination.vue";
import OrderCreditNotesPagination from "@/v3/components/pagination/OrderCreditNotesPagination.vue";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import EmailPreviewForm from "@/v3/components/forms/EmailPreviewForm.vue";
import OrderQuotationsPagination from "@/v3/components/pagination/OrderQuotationPagination.vue";
import SelectQuoteForm from "@/v3/components/forms/SelectQuoteForm.vue";
import NewCreditNoteForm from "@/v3/components/forms/NewCreditNoteForm.vue";
import ReconcileOrderForm from "@/v3/components/forms/ReconcileOrderForm.vue";
import GoodsInNoteForm from "@/v3/components/forms/GoodsInNoteForm.vue";
import ReturnsForm from "@/v3/components/forms/ReturnsForm.vue";
import OrderAttachments from "@/v3/components/pagination/OrderAttachments.vue";
import OrderNotes from "@/v3/components/pagination/OrderNotes.vue";
import SplitOrderForm from "@/v3/components/forms/SplitOrderForm.vue";
import UpdateScheduledFileForm from "@/v3/components/forms/UpdateScheduledFileForm.vue";
import ReadyForQuoteForm from "@/v2/components/forms/ReadyForQuoteForm.vue";
import ReviewQuotesForm from "@/v2/components/forms/ReviewQuotesForm.vue";
import Modal from "@/v3/components/modals/Modal.vue";

export default {
  name: 'SingleOrderDetails',
  components: {
      Modal,
      ReviewQuotesForm, ReadyForQuoteForm,
      UpdateScheduledFileForm, SplitOrderForm,
      OrderNotes,
      OrderAttachments,
      ReturnsForm,
      GoodsInNoteForm,
      ReconcileOrderForm, NewCreditNoteForm,
      SelectQuoteForm,
      OrderQuotationsPagination,
      EmailPreviewForm,
       FormAction, ConfirmationAction,
      OrderCreditNotesPagination,
      OrderInvoicesPagination,
      DownloadPdfInline,
    BlankHeader,
    HeaderLink,
    Loading,
    OrderActivityFeed,
    OrderAttachmentsPagination,
    OrderBasicDetailsPagination,
    OrderContractInfoPagination,
    OrderItemsPagination,
    OrderSchedulePagination,
    StatusBadge
  },
    data(){
      return {
          editing: false,
          editingOrderItems: false,
          tabOpen: 'order-list',
          showModal: false
      }
    },
    props:{
      details: {
          type: Object,
          default: null
      },
        hideTable: {
          type: Boolean,
            default: false
        },
        loadingDetails:{
          type: Boolean,
            default: false
        },
        hasTable:{
          type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        initials() {
            if (this.orderUser.entity && this.orderUser.entity.properties.get('name')) {
                return this.orderUser.entity.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        orderUser(orderEntity) {
            let orderUser = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("user");
                })
                .first();

            if (!orderUser) {
                return null;
            }

            return orderUser;
        },
        orderSite(orderEntity) {
            let orderSite = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("site");
                })
                .first();

            if (!orderSite) {
                return null;
            }

            return orderSite;
        },
        orderProject(orderEntity) {
            let orderProject = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("project");
                })
                .first();

            if (!orderProject) {
                return null;
            }

            return orderProject;
        },
        selectedQuote(entity) {
            let selectedQuote = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("quote");
                })
                .first();

            if (!selectedQuote) {
                return null;
            }

            return selectedQuote;
        },
        cmsQuote(orderEntity) {
            let selectedQuote = this.details.entities
                .filter(function (entity) {
                    return entity.rels.contains("cms_quote");
                })
                .first();

            if (!selectedQuote) {
                return null;
            }

            return selectedQuote;
        },
        updateAction() {
            if (!this.details) {
                return null;
            }
            return this.details.actions.filter(action => action.name === 'update').first()
        },
        updateActionData() {
            if (!this.updateAction || !this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        remainingActions() {
            return this.details.actions
                .filter(action =>
                    action.name !== 'update' &&
                    action.name !== 'update-invoice' &&
                    action.name !== 'add-new-item' &&
                    action.name !== 'search-products' &&
                    action.name !== 'add-product-to-order' &&
                    action.name !== 'filter-products' &&
                    action.name !== 'get-pre-priced-values' &&
                    action.name !== 'add-comment' &&
                    action.name !== 'flag-order' &&
                    action.name !== 'select-quote-for-approval' &&
                    action.name !== 'reconcile-order' &&
                    action.name !== 'create-invoice-in' &&
                    action.name !== 'add-delivery-confirmations' &&
                    action.name !== 'add-credit-note' &&
                    action.name !== 'update-quote' &&
                    action.name !== 'select-quote-and-ask-cms-to-beat' &&
                    action.name !== 'request-update' &&
                    action.name !== 'add-goods-in-note' &&
                    action.name !== 'mark-as-fully-delivered' &&
                    action.name !== 'split-order'
                );
        },
        updateQuoteAction(){
            return this.details.actions.filter(action=> action.name === 'update-quote').first()
        },
        createInvoiceAction(){
            return this.details.actions.filter(action=> action.name === 'reconcile-order' || action.name === 'create-invoice-in').first()
        },
        createCreditNoteAction(){
            return this.details.actions.filter(action=> action.name === 'add-credit-note').first()
        }
    },
    methods:{
        openItemInNewTab(){
            let selfLink = this.details.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            window.open(parts[1], '_blank');
        },
        editDetails(){
            this.editing = true;
        },
        saveDetails() {
            if(!this.submittingModal) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submittingModal = true;
                this.updateAction.perform(this.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.editing = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.details = res.body;
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        async saveItems(){
            if (!this.orderItemsSubmitting) {
                this.orderItemsSubmitting = true;
                this.$refs.orderItems.updateItems().then(res => {
                    this.editingOrderItems = false;
                    this.orderItemsSubmitting = false;
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);

                    this.$store.dispatch(
                        "setMessage",
                        'The order was saved successfully'
                    );
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.orderItemsSubmitting = false;
                })
            }
        },
        removeItem(){
            let entity = {'entity': this.details};
            this.$emit('setLoading');
            this.$emit('getDetails', entity);
        },
        getTitle(name, title){
            if(name === 'mark-as-ready-for-quote'){
                return 'Get Quote';
            }
            if(name === 'mark-as-take-off'){
                return 'Get Take Off';
            }
            if(name === 'delete-order'){
                return 'Delete';
            }
            if(name === 'send-quotation-request'){
                return 'Quote Req.'
            }
            if(name === 'mark-as-quotes-received'){
                return 'Quotes Received';
            }
            if(name === 'create-return-request'){
                return 'Return';
            }
            if(name === 'approve-quotes'){
                return 'Approve';
            }
            if(name === 'reject-quotes'){
                return 'Reject';
            }
            return title;
        },
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject') || action.includes('delete')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        formSuccess(res){
                let entity = {'entity': this.details};
                this.$emit('setLoading');
                this.$emit('getDetails', entity);

        },
        openTab(tabName){
            if(!this.editing && !this.editingOrderItems){
                this.tabOpen = tabName;
            }else{
                this.showModal = true;
            }
        }
    }
}
</script>

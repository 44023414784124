<template>
    <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
        <div class="sm:flex sm:items-start flex-col h-full w-full">
            <h3 v-if="bannerText !== null" class="text-lg leading-6 font-medium mb-2 mx-auto bg-v3-gray-100 dark:bg-v3-gray-800 w-full text-center py-3 rounded text-white dark:text-v3-gray-200">
                {{ bannerText }}
            </h3>
            <h3 class="text-lg leading-6 font-medium text-v3-gray-800 dark:text-v3-gray-200 mb-2" id="modal-headline">
                <slot id="header">View File</slot>
            </h3>
            <div class="mt-3 text-center sm:mt-0 sm:text-left relative h-full w-full">
                <iframe v-if="url.includes('/api/files')"
                    :src="`data:${mimeType};base64,${file}`"
                    download="some_name.gif"
                    style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px" height="100%" width="100%"
                ></iframe>
                <img v-else :src="url" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;position:absolute;top:0px;left:0px;right:0px;bottom:0px"/>
            </div>
        </div>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        props: {
            url: {
                type: String
            },
            bannerText: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                file: null,
                mimeType: null
            }
        },
        created() {
            this.downloadWithVueResource();
        },
        methods: {
            downloadWithVueResource() {
                console.log(this.url);
                if(this.url.includes('/api/files')) {
                    this.$http({
                        method: 'get',
                        url: this.url,
                        headers: {Authorization: `Bearer ${this.accessToken}`}
                    })
                        .then(response => {
                            this.file = response.body.file;
                            this.mimeType = response.body.mime_type;
                        })
                        .catch((error) => {
                            if (error.status === 401) {
                                alert('Please login to download the file.');
                            } else {
                                alert('There was an error whilst downloading the file.');
                            }
                        })
                }
            },
            removeFromDisplay() {
                this.$emit('removeFromDisplay');
            },
            perform() {
                this.$emit('perform');
            }
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            })
        }
    }
</script>

<style scoped>
</style>
